import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function PrivatumoPolitika() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Box sx={{
        textAlign: 'left',
        padding: { xs: 2, sm: 4 },
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
      }}>
        <Typography variant={isMobile ? 'h5' : 'h4'} component="h1" gutterBottom sx={{ fontWeight: 600, color: '#0C5068', mb: 3 }}>
          Privatumo politika
        </Typography>
        
        <Section>
          MB Elzee group, įmonės kodas 305654625, buveinės adresas J. Balčikonio g. 7 (toliau – „mes" arba „Įmonė"), gerbia Jūsų privatumą ir įsipareigoja saugoti Jūsų asmens duomenis pagal Bendrąjį duomenų apsaugos reglamentą (BDAR) ir kitus taikomus teisės aktus.
        </Section>

        <Section title="1. Duomenų tvarkymo tikslas">
          Jūsų el. pašto adresą renkame ir tvarkome tik tam, kad galėtume informuoti Jus apie mūsų paslaugas ir naujienas.
        </Section>

        <Section title="2. Teisinis pagrindas">
          Jūsų sutikimas, kurį išreiškiate pateikdami savo el. pašto adresą.
        </Section>

        <Section title="3. Duomenų saugojimo laikotarpis">
          Jūsų el. pašto adresą saugosime tol, kol neatšauksite savo sutikimo arba nenutrauksime savo veiklos.
        </Section>

        <Section title="4. Jūsų teisės">
          Turite teisę bet kada atšaukti savo sutikimą, prašyti prieigos prie savo duomenų, juos ištaisyti, ištrinti, apriboti jų tvarkymą, juos perkelti ir nesutikti su jų tvarkymu.
        </Section>

        <Typography variant="body2" sx={{ mt: 4, fontStyle: 'italic', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
          Dėl visų klausimų, susijusių su Jūsų asmens duomenų tvarkymu, galite kreiptis el. paštu info@elzeego.com arba telefonu +37067617797.
        </Typography>
        <Button component={Link} to="/" variant="contained" sx={{ mt: 4 }}>
          Grįžti į pagrindinį puslapį
        </Button>
      </Box>
    </Container>
  );
}

function Section({ title, children }) {
  return (
    <Box sx={{ mb: 3 }}>
      {title && (
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600, color: '#0C5068', fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
          {title}
        </Typography>
      )}
      <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        {children}
      </Typography>
    </Box>
  );
}

export default PrivatumoPolitika;