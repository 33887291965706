import React, { useState } from 'react';
import { Box, TextField, Button, Typography, InputAdornment, useTheme, useMediaQuery } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SnackbarComponent from './SnackbarComponent';

function Unsubscribe() {
  const [email, setEmail] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://yolodock-latest.onrender.com/api/unsubscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Nepavyko atsisakyti prenumeratos');
      }

      setSnackbar({ open: true, message: 'Sėkmingai atsisakėte prenumeratos', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ 
      mb: isMobile ? -2 : 0, 
      textAlign: 'center',
      px: 2,
    }}> 
      <Typography 
        variant={isMobile ? 'h5' : 'h4'} 
        component="h1" 
        gutterBottom 
        sx={{ 
          fontWeight: 600, 
          whiteSpace: 'pre-line',
          lineHeight: 1.2,
        }}
      >
        Atsisakyti prenumeratos
      </Typography>
      <Typography variant="body1" sx={{ mb: isMobile ? 2 : 4 }}>
        Įveskite savo el. pašto adresą, kad atsisakytumėte prenumeratos.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="El. pašto adresas"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
          type="email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button 
          type="submit" 
          variant="contained" 
          size={isMobile ? 'medium' : 'large'}
          endIcon={<ArrowForwardIcon />}
          sx={{ 
            mt: 2, 
            width: isMobile ? '100%' : '200px',
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          Atsisakyti 
        </Button>
      </form>
      <SnackbarComponent
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
}

export default Unsubscribe;