import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { Mic, BrainCog, FileQuestion, MessageSquare } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card>
    <CardContent>
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <Icon />
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

export default function SaasFeatures() {
  return (
    <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 4, py: 8 }}>

      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <FeatureCard
            icon={Mic}
            title="Įrašyk ir gauk santrauką"
            description="Vienas mygtuko paspaudimas ir gausi detaliai aprašytas paskaitas, susitikimus ir mokymosi sesijas. Nuo mokyklos suolo iki CEO."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard
            icon={BrainCog}
            title="AI klausimų generatorius"
            description="Nežinai ko paklausti per paskaitą ar meetą? Per sekundę gausi klausimus iš live įrašo ir tu jau protingiausias kambaryje!"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard
            icon={FileQuestion}
            title="Interaktyvus testų kūrėjas"
            description="Susikurk testus iš PDF, tekstinių failų ar nuotraukų. Gali spręsti, kiek nori, sekti rezultus ir testais dalintis su draugais!"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard
            icon={MessageSquare}
            title="AI pokalbių asistentas"
            description="Naujausiu CHAT-GPT pagrindu veikiantis pokalbių modulis, apmokytas Lietuvišku kontekstu, 39 teisės aktais ir pritaikytas tavo konkretiems poreikiams."
          />
        </Grid>
      </Grid>
      <Box textAlign="center">

      </Box>
    </Box>
  );
}