import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import Header from './header';
import WaitingListForm from './waitinglistform';
import Footer from './footer';
import PrivatumoPolitika from './PrivatumoPolitika';
import NaudojimoSalygos from './NaudojimoSalygos';
import Unsubscribe from './Unsubscribe';
import SaasFeatures from './SaasFeatures';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Router>
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Container 
                maxWidth={isMobile ? 'xs' : 'sm'} 
                sx={{ 
                  flexGrow: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  py: isMobile ? 2 : 4
                }}
              >
                <WaitingListForm />
              </Container>
              <SaasFeatures />
            </>
          } />
          <Route path="/privacy" element={<PrivatumoPolitika />} />
          <Route path="/terms" element={<NaudojimoSalygos />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;