import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

function WaitingListForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ 
      mb: isMobile ? -2 : 0, 
      textAlign: 'center',
      px: 2,
    }}> 
      <Typography 
        variant={isMobile ? 'h5' : 'h4'} 
        component="h1" 
        gutterBottom 
        sx={{ 
          fontWeight: 600, 
          whiteSpace: 'pre-line',
          lineHeight: 1.2,
        }}
      >
        {`Pirmiems 200-tams \n1 mėn. nemokamai!`}
      </Typography>
      <Typography variant="body1" sx={{ mb: isMobile ? 2 : 4 }}>
        Universitetas, mokykla, darbas - aš klausau.
      </Typography>
      <Box
        sx={{
          display: 'inline-block',
          padding: '8px 16px',
          borderRadius: '8px',
          backgroundColor: 'rgba(12, 80, 104, 0.1)',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#0C5068' }}>
          Jau prisijungė: 486
        </Typography>
      </Box>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 4, 
          fontWeight: 'bold', 
          color: '#0C5068',
          animation: 'float 3s ease-in-out infinite',
          '@keyframes float': {
            '0%': { transform: 'translateY(0px)' },
            '50%': { transform: 'translateY(-10px)' },
            '100%': { transform: 'translateY(0px)' },
          },
        }}
      >
        Laukiančiųjų eilė užsidarė! Startas jau arti.
      </Typography>
    </Box>
  );
}

export default WaitingListForm;