import React from 'react';
import { AppBar, Toolbar, Box, useTheme, useMediaQuery } from '@mui/material';
import Logo from './logo/logo';

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 0 }}>
      <Toolbar>
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          py: isMobile ? 0 : 0, // Add vertical padding
          mt: 8, // Add margin-top to move the logo lower
        }}>
          <Logo width={isMobile ? 200 : 250} height={isMobile ? 200 : 250} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;