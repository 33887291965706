import React from 'react';
import { Box, Typography, Link as MuiLink, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ py: isMobile ? 2 : 3, textAlign: 'center', borderTop: '1px solid #e0e0e0' }}>
      <Typography variant="body2" color="text.secondary">
        © {new Date().getFullYear()} 7 Dock Inc. Visos teisės saugomos.
      </Typography>
      <Box sx={{ mt: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center' }}>
        <MuiLink component={Link} to="/privacy" color="inherit" sx={{ mx: isMobile ? 0 : 1, my: isMobile ? 0.5 : 0 }}>
          Privatumo politika
        </MuiLink>
        <MuiLink component={Link} to="/terms" color="inherit" sx={{ mx: isMobile ? 0 : 1, my: isMobile ? 0.5 : 0 }}>
          Naudojimo sąlygos
        </MuiLink>
      </Box>
    </Box>
  );
}

export default Footer;