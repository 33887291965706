import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className = '', width = 100, height = 100 }) => {
  return (
    <img
      src="/13.png"
      alt="7 Dock Logo"
      className={`logo ${className}`}
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: width,
        maxHeight: height,
      }}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Logo;